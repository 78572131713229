import React from "react";

function AboveFooter() {

	return (
		<div id="above-footer">
			<img id="leaves" src="images/leaves.png" alt="Leaves" />
		</div>
  )
}

export default AboveFooter